body {
  font-family: "Helvetica";
}
h1,
h2,
h3 {
  margin: 0.5rem 0;
}
h1 {
  font-size: 1.5rem;
}
h2 {
  font-size: 1.2rem;
}
h3 {
  font-size: 1rem;
}
a {
  text-decoration: none;
}

.bold {
  font-weight: bold;
}

.larger-font {
  font-size: larger;
}

.header {
  background-color: #b9d8c5;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.header-title {
  font-family: "Amaranth", sans-serif;
  font-size: xxx-large;
  color: rgb(77, 77, 77);
}

.navbar {
  background-color: #e0e0e0;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.navbar-label {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5rem;
  color: rgb(107, 107, 107);
  font-weight: bold;
}

.main-page {
  margin-top: 1rem;
}

.block {
  background-color: #e0e0e0;
  padding: 1rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
}

.text-right {
  text-align: right;
}

img.small {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-height: 8rem;
}

img.big {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-height: 24rem;
  padding-bottom: 2rem;
}

.product-block {
  background-color: #ffffff;
  /* padding: 1rem; */
  border-radius: 0.5rem;
}

.product-page-name {
  font-family: "Dancing Script", cursive;
  font-size: xxx-large;
  font-weight: bold;
  padding-top: 2rem;
  padding-bottom: 2rem;
  text-align: center;
}

.product-page-desc {
  margin-left: 1rem;
}

.product-page-additional-images {
  font-size: x-large;
  font-weight: medium;
  /* font-style: italic; */
  margin-bottom: 2rem;
}

.product-item-name {
  /* font-family: "Dancing Script", cursive; */
  color: #808080;
  font-size: large;
  font-weight: bold;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-align: center;
}

.product-item-in-cart {
  text-align: center;
  color: #808080;
}

.product-item-price {
  color: #1ab869;
  font-size: large;
  font-weight: bold;
  margin-bottom: 2rem;
  text-align: center;
}

.product-item-field {
  margin-top: 0.5rem;
}

.product-item-field-label {
  font-weight: bold;
}

.product-item-field-value {
  /* intentionally blank for now */
}

.price {
  font-size: large;
  font-weight: medium;
}

.cart-block {
  background-color: hsl(0, 0%, 100%);
  padding: 1rem;
  border-radius: 0.5rem;
  margin-top: 2rem;
}

.order-btn {
  margin-top: 1rem;
}

.product-link {
  text-decoration: none;
  color: black;
}

.cart-item-name {
  font-size: large;
  /* font-weight: bold; */
}

.cart-item-number {
  font-weight: bold;
}

.cart-item-price {
  font-size: large;
  font-weight: bold;
  text-align: right;
}

.cart-message {
  font-size: x-large;
  font-weight: bold;
  font-style: italic;
  margin-bottom: 2rem;
}

.cart-header-price {
  font-size: medium;
  font-weight: bold;
  text-align: right;
}

.cart-total {
  font-size: large;
  font-weight: bold;
  text-align: right;
}

.cart-delete-link {
  color: rgb(0, 83, 191);
  margin-top: 1rem;
}

.cart-delete-link:hover {
  color: rgb(189, 0, 0);
  cursor: pointer;
}

.order-form {
  margin: 2rem;
}

.order-form-row {
  margin-top: 1rem;
}

.order-form-message {
  font-weight: bold;
  margin: 2rem;
}

.order-form-items {
  margin: 0rem;
}

.order-form-item {
  padding: 0;
}

.order-form-total-message {
  font-weight: medium;
  margin-left: 2rem;
  margin-top: 1rem;
}

.mandatory-field-label-indicator {
  font-weight: bold;
  font-size: large;
  color: red;
}

.hidden-field {
  display: none;
}

.clickable {
  cursor: pointer;
}

.selected-image-in-viewer {
  /* background-color: grey; */
}

.product-image-viewer-image {
  max-width: 100% !important;
  max-height: 80% !important;
}

.leftnav {
  margin-top: 1em;
}

.leftnav-link {
  padding-bottom: 10px;
}

.leftnav-label {
  padding-bottom: 10px;
  margin: 0px;
  font-weight: bold;
}

.leftnav-label.extra-top-space {
  margin-top: 1em;
}

.category-nav {
  margin-top: 1em;
  /* background-color: rgb(196, 225, 215); */
}

.feature-title {
  padding: 1em;
  font-weight: bolder;
  font-size: xx-large;
  font-family: "Dancing Script", cursive;
  color: rgb(213, 73, 97);
  text-align: center;
}

/* Image viewer modal dialog sizing */
.image-viewer-dialog {
  max-width: 950px !important;
  max-height: 950px !important;
}

.image-viewer-image {
  max-height: 900px;
  max-width: 900px;
}
